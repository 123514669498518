<template>
  <div
    class="btn"
    v-bind:class="getClass"
    v-html="getText"
    v-on:click="onClick"
  ></div>
</template>
<script>
export default {
  name: "TableImageUpload",
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    value: {
      default: () => null,
    },
    row: {
      type: Object,
      default: () => {},
    },
    rowIndex: {
      default: 0,
    },
  },
  methods: {
    onClick() {
      this.$parent.$parent.$emit("image-upload", this.rowIndex);
    },
  },
  computed: {
    getText() {
      return this.value && this.value.files && this.value.files.length
        ? "<i class='fas fa-image'></i>"
        : "<i class='fas fa-upload'></i>";
    },
    getClass() {
      return this.value && this.value.files && this.value.files.length
        ? "btn-outline-primary"
        : "btn-outline-secondary";
    },
  },
};
</script>
